<template>
  <div>
    <v-form ref="generalSettingsForm" v-model="isFormValid" class="multi-col-validation" @submit.prevent="onSubmit">
      <v-row class="justify-center">
        <v-col cols="12" md="12" class="mx-auto pt-0">
          <v-card flat class="mb-5">
            <v-card-text class="px-0 pt-0">
              <h2>Search & Filter</h2>
            </v-card-text>
            <v-card-text class="d-flex align-center flex-wrap px-0 py-0">
              <v-row class="px-3">
                <v-col cols="12" md="4" class="px-0">
                  <v-text-field
                    v-model="searchQuery"
                    single-line
                    dense
                    outlined
                    hide-details
                    clearable
                    label="Search"
                    placeholder="Search"
                    class="mb-5 me-3"
                    @input="searchData"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="px-0">
                  <v-text-field
                    v-model="filterByUserId"
                    clearable
                    single-line
                    dense
                    outlined
                    hide-details
                    label="Filter by user ID"
                    placeholder="Filter by user ID"
                    class="mb-5 me-3"
                    @input="searchById"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="px-0">
                  <v-select
                    v-model="filterByRole"
                    :items="roleOptions"
                    label="Filter by role"
                    placeholder="Filter by role"
                    clearable
                    single-line
                    outlined
                    dense
                    hide-details
                    class="mb-5 me-0 pr-0"
                    @change="setRole"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex align-center flex-wrap px-0 py-0 border-top justify-end">
              <span class="pt-5 pb-5 pr-0">
                <v-btn color="primary" class="mr-3" @click="assignConsultantModal = true">
                  Assign Customer Success Manager
                </v-btn>
                <v-btn color="secondary" :disabled="creatingBav" @click="addEmployeeModal = true">
                  Create bAV Manager
                </v-btn>
              </span>
            </v-card-text>

            <v-data-table
              :headers="headers"
              :items="loadedUsers"
              :server-items-length="totalUsers"
              :loading="creatingBav"
              :footer-props="{
                'items-per-page-options': [50, 100, 150],
                'items-per-page-text': 'Items per page',
              }"
              class="text-no-wrap pointer v-data-table--roles"
              @update:items-per-page="setLimit"
              @click:row="handleRowClick"
              @pagination="handlePagination"
            >
              <template #[`header.trending`]>
                <v-icon size="22">
                  {{ icons.mdiTrendingUp }}
                </v-icon>
              </template>

              <template #[`item.firstName`]="{ item }">
                <span class="text-no-wrap th">{{ item.firstName || '-' }}</span>
              </template>

              <template #[`item.lastName`]="{ item }">
                <span class="text-no-wrap th">{{ item.lastName || '-' }}</span>
              </template>

              <template #[`item.id`]="{ item }">
                <span>
                  <v-btn icon small @click="copy(item.id)">
                    <v-icon size="18">
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </v-btn>
                </span>
                <span class="text-no-wrap th">{{ item.id.slice(0, 12) }}...</span>
              </template>
              <template #[`item.registrations[0].roles`]="{ item }">
                <span v-if="item.registrations[0].roles" class="text-no-wrap th">
                  <span v-if="item.registrations[0].roles.includes('ROLE_KEY_ACCOUNT')"> AM </span>
                  <span v-else>
                    {{ item.registrations[0].roles.includes('ROLE_BAV_MANAGER') ? 'bAV Manager' : 'Employee' }}
                  </span>
                </span>
                <span v-else class="text-no-wrap th"> - </span>
              </template>

              <template #[`item.email`]="{ item }">
                <span class="text-no-wrap th">{{ item.email || item.username || '-' }}</span>
              </template>

              <!-- actions -->
              <template #[`item.actions`]="{ item }">
                <div class="d-flex align-center justify-center">
                  <!-- edit -->
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon small v-bind="attrs" @click="$router.push('/profile/' + item.id)" v-on="on">
                        <v-icon size="18">
                          {{ icons.mdiPencilOutline }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <!-- remove  consultant or HR-->
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn icon small v-bind="attrs" @click="unassignUser(item)" v-on="on">
                        <v-icon size="18">
                          {{ icons.mdiMinusCircle }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Unassign</span>
                  </v-tooltip>
                </div>
              </template>

              <template slot="no-data">
                <div class="my-5">
                  <p>Unfortunately there are no results</p>
                </div>
              </template>
            </v-data-table>
          </v-card>

          <ModalWindow
            :is-open="addEmployeeModal"
            title="Create BAV Manager"
            :maxWidth="700"
            :hasForm="true"
            :centered="true"
          >
            <template #content>
              <add-employee-form
                :personalData="personalData"
                :companiesOptions="orgCompanies"
                :preselectedCompany="$route.params.id"
                :multipleCompanySelection="true"
                formId="addEmployeeForm"
                @created="handleBavCreated"
              />
            </template>
            <template #actions>
              <v-btn outlined color="primary" class="btn--default" @click="addEmployeeModal = false"> Cancel </v-btn>
              <v-btn color="primary" class="btn--default" type="submit" form="addEmployeeForm"> Add </v-btn>
            </template>
          </ModalWindow>
          <ModalWindow
            :is-open="assignConsultantModal"
            :hasForm="true"
            title="Assign Consultant"
            message="Please select which consltant is to be assigned to this company."
            :max-width="500"
          >
            <template #content>
              <v-select
                v-model="assignedConsultant"
                :items="consultantOptions"
                label="Consultant"
                persistent-hint
                hide-details
                class="py-0 px-15"
              ></v-select>
            </template>
            <template #actions>
              <v-btn outlined color="primary" @click="assignConsultantModal = false"> Cancel </v-btn>
              <v-btn color="primary" @click="assignNewConsultant">Assign</v-btn>
            </template>
          </ModalWindow>
          <ModalWindow :is-open="editHrModal" title="Edit HR Manager">
            <template #content>
              <p class="text-base"></p>
            </template>
            <template #actions>
              <v-btn outlined color="primary" @click="editHrModal = false"> Cancel </v-btn>
              <v-btn color="primary"> Save</v-btn>
            </template>
          </ModalWindow>
        </v-col>
      </v-row>
      <div class="d-flex justify-space-between mt-5">
        <v-btn outlined @click="$emit('stepBack')"> Back</v-btn>
        <v-btn color="primary" type="submit" @click="$emit('next')"> Continue</v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from '@vue/composition-api';
import { mdiCalendar, mdiEmailOutline, mdiCellphone, mdiMinusCircle, mdiPencilOutline, mdiContentCopy } from '@mdi/js';
import { required, emailValidator, phoneNumberValidator } from '@core/utils/validation';
import ModalWindow from '@/components/modal/ModalWindow';
import { updateAssignedCompanies, fetchHRManagerByCompany } from '../../../api/fusion-auth';
import {
  getAssignedCustomerSuccessManager,
  fetchConsultants,
  unassignConsultant,
  assignConsultant,
} from '../../../api/consultant';
import { ROLE_BAV_MANAGER } from '../../../constants';
import { SALUTATION_MR } from '@/constants';
import AddEmployeeForm from '@/components/employee-add/AddEmployeeForm.vue';

export default {
  components: { ModalWindow, AddEmployeeForm },
  props: ['orgCompanies', 'companyData'],
  setup() {
    const vm = getCurrentInstance().proxy;
    const loadedUsers = ref([]);
    const totalUsers = ref(0);
    const menu = ref(false);
    const creatingBav = ref(false);
    const limit = ref(50);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');
    const filterByUserId = ref('');
    const filterByRole = ref('');
    const isFormValid = ref(false);
    const assignConsultantModal = ref(false);
    const addEmployeeModal = ref(false);
    const editHrModal = ref(false);
    const assignedAm = ref([]);
    const amOptions = ref([]);
    const consultantOptions = ref([]);
    const assignedConsultant = ref(null);
    const roleOptions = [
      {
        text: 'bAV Manager',
        value: ROLE_BAV_MANAGER,
      },
    ];
    const headers = computed(() => {
      return [
        {
          text: 'FIRST NAME',
          value: 'firstName',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: 'LAST NAME',
          value: 'lastName',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: 'USER ID',
          value: 'id',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: 'EMAIL',
          value: 'email',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: 'ROLE',
          value: 'registrations[0].roles',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'text-uppercase',
        },
      ];
    });
    const fetchAll = () => {
      fetchHRManagerByCompany(vm.$route.params.id).then((res) => {
        loadedUsers.value = res.data.users || [];
        totalUsers.value = res.data.total;
      });
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchAll();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchAll();
    };

    const searchData = () => {
      if (!searchQuery.value) return fetchAll();
      loadedUsers.value = filterByQuery(loadedUsers.value, searchQuery.value);
    };

    const searchById = () => {
      if (!filterByUserId.value) return fetchAll();
      loadedUsers.value = filterByQuery(loadedUsers.value, filterByUserId.value);
    };

    const filterByQuery = (array, str) => {
      return array.filter((o) => {
        return Object.keys(o).some((k) => {
          if (typeof o[k] === 'string') return o[k].toLowerCase().includes(str.toLowerCase());
        });
      });
    };

    const copy = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        vm.$store.commit('showMessage', {
          text: 'Copied',
          color: '#4caf50',
          timeout: '1000',
        });
      });
    };

    const handleRowClick = (company) => {
      // vm.$router.push(`companies/${company.id}`);
    };
    const setRole = () => {
      fetchAll();
    };

    const fetchConsultant = () => {
      fetchConsultants().then((res) => {
        consultantOptions.value = res.data.map((c) => {
          return { text: `${c.firstName} ${c.lastName}`, value: c.id };
        });
      });
      getAssignedCustomerSuccessManager(vm.$route.params.id)
        .then((res) => {
          if (res.data.id) {
            assignedConsultant.value = {
              text: `${res.data.firstName} ${res.data.lastName}`,
              value: res.data.id,
            };
          }
        })
        .catch((e) => {});
    };

    const assignNewConsultant = async () => {
      // remove current consultant since only 1 allowed
      try {
        if (assignedConsultant.value.value) {
          await unassignConsultant(vm.$route.params.id, assignedConsultant.value.value);
        }

        await assignConsultant(vm.$route.params.id, assignedConsultant.value);
        assignedConsultant.value = null;
        fetchConsultant();
      } catch (e) {
        // TODO: add sentry
      } finally {
        assignConsultantModal.value = false;
      }
    };

    const handleBavCreated = (user) => {
      creatingBav.value = true;
      addEmployeeModal.value = false;
      // Delay for BE to create a record
      setTimeout(() => {
        const { companies } = user;
        updateAssignedCompanies(user.id, companies).then(() => {
          // Delay for BE
          setTimeout(() => {
            fetchAll();
            creatingBav.value = false;
          }, 5000);
        });
      }, 10000);
    };

    const unassignUser = (user) => {
      const filteredCompanies = user.data.companyIdsAssigned.filter((item) => item !== vm.$route.params.id);
      const payloadArr = [...filteredCompanies.map(String)];

      updateAssignedCompanies(user.id, payloadArr).then(() => {
        creatingBav.value = true;
        // Delay for BE
        setTimeout(() => {
          fetchAll();
          creatingBav.value = false;
        }, 5000);
      });
    };

    fetchConsultant();

    const onSubmit = () => {
      vm.$emit('next');
    };

    return {
      onSubmit,
      menu,
      isFormValid,
      headers,
      assignConsultantModal,
      addEmployeeModal,
      editHrModal,
      handleRowClick,
      assignedConsultant,
      setLimit,
      handlePagination,
      searchQuery,
      searchData,
      searchById,
      filterByUserId,
      filterByRole,
      setRole,
      copy,
      unassignUser,
      roleOptions,
      loadedUsers,
      totalUsers,
      amOptions,
      assignedAm,
      consultantOptions,
      assignNewConsultant,
      handleBavCreated,
      creatingBav,

      // icons
      icons: {
        mdiCalendar,
        mdiEmailOutline,
        mdiCellphone,
        mdiMinusCircle,
        mdiContentCopy,
        mdiPencilOutline,
      },
      validators: {
        required,
        emailValidator,
        phoneNumberValidator,
      },
      personalData: {
        salutation: SALUTATION_MR,
        firstName: '',
        lastName: '',
        city: '',
        postalCode: '',
        streetAddress: '',
        country: '',
        cityOfBirth: '',
        countryOfBirth: '',
        dateOfBirth: '',
        emailPersonal: '',
        emailBusiness: '',
        phoneNumber: '',
        contract: false,
        language: 'de',
      },
    };
  },
};
</script>
<style lang="scss">
.v-data-table--roles {
  .v-data-footer {
    padding: 0 !important;
  }
}
</style>
