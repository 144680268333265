<template>
  <div>
    <v-form
      v-if="companyData && companyConfig"
      ref="generalSettingsForm"
      v-model="isFormValid"
      class="multi-col-validation"
      @submit.prevent="onSubmit"
    >
      <v-row>
        <v-col md="6" cols="12">
          <v-text-field
            id="company-id"
            v-model="companyData.id"
            readonly
            dense
            outlined
            label="Company ID"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" cols="12">
          <v-text-field
            id="company-name"
            v-model="companyData.companyName"
            dense
            outlined
            label="Company name"
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            id="employee-facing-name"
            v-model="companyData.employeeFacingName"
            dense
            outlined
            label="Employee facing name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" cols="12">
          <v-select
            v-model="companyData.languagePreference"
            :items="availableLanguages"
            outlined
            hide-details
            dense
            label="Language preference"
            class="select-router-transition"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider class="mt-7 mb-5 v-divider--stepper"></v-divider>
      <v-row>
        <v-col md="6" cols="12">
          <label for="twofa" class="v-label v-label--active theme--light text-xs">Two factor settings</label>
          <v-select
            v-model="companyData.settings2fa"
            :items="twoFactorSettingsOptions"
            outlined
            hide-details
            dense
            class="select-router-transition mb-6"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider class="mt-1 mb-5 v-divider--stepper"></v-divider>
      <v-row>
        <v-col md="6" cols="12">
          <v-file-input
            id="company-logo"
            v-model="uploadedLogo"
            label="Company logo"
            hide-details
            @change="handleLogoUpload"
          ></v-file-input>
        </v-col>
        <v-col v-if="uploadedLogo" md="6" cols="12">
          <v-img max-height="150" :src="createImg(uploadedLogo)" max-width="250"> </v-img>
        </v-col>
        <v-col v-if="!uploadedLogo && companyConfig.logoSrc" md="6" cols="12">
          <v-img max-height="150" lazy-load :src="companyConfig.logoSrc" max-width="250"> </v-img>
        </v-col>
      </v-row>
      <p></p>
      <v-divider class="mt-8 mb-8 v-divider--stepper"></v-divider>
      <v-row>
        <v-col md="6" cols="12">
          <v-text-field
            id="value-in-percent"
            v-model="companyConfig.contribution.employerSubsidy"
            type="number"
            min="0"
            max="100"
            dense
            outlined
            label="Subsidy in %"
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            v-model="companyConfig.contribution.employerContribution"
            dense
            outlined
            label="Contribution in €"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="6" cols="12">
          <v-text-field
            id="welcome-video-de"
            v-model="companyConfig.videoDe"
            dense
            outlined
            label="Welcome video URL (DE)"
          ></v-text-field>
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            id="welcome-video-en"
            v-model="companyConfig.videoEn"
            dense
            outlined
            label="Welcome video URL (EN)"
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mt-5">
        <v-btn color="primary" type="submit" :loading="loading">
          {{ $t('buttonContinue') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api';
import { mdiCalendar, mdiEmailOutline, mdiCellphone } from '@mdi/js';
import { required, emailValidator, phoneNumberValidator } from '@core/utils/validation';
import { updateCompanyConfig } from '../../../api/onboarding';
import { updateCompany, changeCompany2faSettings } from '../../../api/companies';
import { remove2faForAllByCompany } from '../../../api/fusion-auth';

export default {
  components: {},
  props: ['companyData', 'companyConfig'],
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const loading = ref(false);
    const twoFactorSettings = ref('DISABLE');
    const contributionType = ref('variable');
    const contributionValue = ref(0);
    const languagePref = ref({ language: 'en', form: 'LANGUAGE_FORMAL' });

    const uploadedLogo = ref(null);
    const handleLogoUpload = (file) => {
      uploadedLogo.value = file;
    };
    const createImg = (file) => {
      if (!file) return '';
      return URL.createObjectURL(file);
    };

    const isFormValid = ref(false);
    const twoFactorSettingsOptions = [
      { text: 'Disabled', value: 'DISABLE' },
      { text: 'Enabled, forced setup', value: 'FORCE' },
      { text: 'Enabled, optional setup', value: 'NOT_FORCE' },
    ];
    const availableLanguages = [
      {
        text: 'English',
        value: { language: 'en', form: 'LANGUAGE_FORMAL' },
      },
      {
        text: 'German formal',
        value: { language: 'de', form: 'LANGUAGE_FORMAL' },
      },
      {
        text: 'German informal',
        value: { language: 'de', form: 'LANGUAGE_INFORMAL' },
      },
    ];

    const removeExistingTwoFactorMethods = async () => {
      await remove2faForAllByCompany(vm.$route.params.id);
    };

    const combineNewCompanyConfig = () => {
      const payload = new FormData();
      const companyConfigPayload = {
        contribution: {
          employerSubsidy: Number(props.companyConfig.contribution.employerSubsidy) / 100 ?? null,
          employerContribution: props.companyConfig.contribution.employerContribution ?? null,
        },
        videoDe: props.companyConfig.videoDe,
        videoEn: props.companyConfig.videoEn,
      };

      payload.append(
        'company',
        new Blob([JSON.stringify(companyConfigPayload)], {
          type: 'application/json',
        }),
      );
      payload.append('logo', uploadedLogo.value);

      return payload;
    };

    const combineNewCompanyData = () => {
      return {
        employeeFacingName: props.companyData.employeeFacingName,
        companyName: props.companyData.companyName,
        languagePreference: props.companyData.languagePreference,
        settings2fa: props.companyData.settings2fa,
      };
    };

    const onSubmit = () => {
      loading.value = true;

      const companyConfigPayload = combineNewCompanyConfig();
      const companyDataPayload = combineNewCompanyData();

      updateCompanyConfig(vm.$route.params.id, companyConfigPayload).then(() => {
        updateCompany(vm.$route.params.id, companyDataPayload).then(() => {
          changeCompany2faSettings(vm.$route.params.id, companyDataPayload.settings2fa).then(() => {
            if (companyDataPayload.settings2fa === 'DISABLE') {
              removeExistingTwoFactorMethods().then(() => {
                loading.value = false;
                vm.$emit('next');
              });
            } else {
              loading.value = false;
              vm.$emit('next');
            }
          });
        });
      });
    };

    return {
      twoFactorSettingsOptions,
      contributionType,
      twoFactorSettings,
      languagePref,
      availableLanguages,
      isFormValid,
      uploadedLogo,
      contributionValue,
      loading,
      createImg,
      handleLogoUpload,
      onSubmit,

      // icons
      icons: {
        mdiCalendar,
        mdiEmailOutline,
        mdiCellphone,
      },
      validators: {
        required,
        emailValidator,
        phoneNumberValidator,
      },
    };
  },
};
</script>
